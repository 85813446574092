{
  "nm": "soft_dev",
  "ddd": 0,
  "h": 1080,
  "w": 1080,
  "meta": { "g": "@lottiefiles/toolkit-js 0.26.1" },
  "layers": [
    {
      "ty": 3,
      "nm": "control",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
        "s": { "a": 0, "k": [188, 188, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [540, 540, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 0, "ix": 11 }
      },
      "ef": [],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "pink button",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [29.297, 6.888, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 62
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 72
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 77
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 145
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [110, 110, 100],
              "t": 150
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0, 0, 100],
              "t": 160
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 289
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 299
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 304
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 383
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [110, 110, 100],
              "t": 388
            },
            { "s": [0, 0, 100], "t": 398 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0.223, 36.537, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 62
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 72
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 77
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 145
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [5],
              "t": 150
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [-90],
              "t": 160
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 289
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 299
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 304
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 383
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [5],
              "t": 388
            },
            { "s": [-90], "t": 398 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [3.666, 0],
                    [0, 0],
                    [0, 3.666],
                    [-3.666, 0],
                    [0, 0],
                    [0, -3.667]
                  ],
                  "o": [
                    [0, 0],
                    [-3.666, 0],
                    [0, -3.667],
                    [0, 0],
                    [3.666, 0],
                    [0, 3.666]
                  ],
                  "v": [
                    [22.408, 6.639],
                    [-22.407, 6.639],
                    [-29.046, -0.001],
                    [-22.407, -6.639],
                    [22.408, -6.639],
                    [29.046, -0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.3333, 0.098, 0.5451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [29.296, 6.888], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 2,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "yellow button",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [29.297, 6.888, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 74
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 84
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 89
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 168
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 173
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 0, 100],
              "t": 183
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 281
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 291
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 296
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 386
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 391
            },
            { "s": [0, 0, 100], "t": 401 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0.223, 57.187, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 74
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 84
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 89
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 168
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 173
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [-90],
              "t": 183
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 281
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 291
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 296
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 386
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 391
            },
            { "s": [-90], "t": 401 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [3.666, 0],
                    [0, 0],
                    [0, 3.667],
                    [-3.666, 0],
                    [0, 0],
                    [0, -3.666]
                  ],
                  "o": [
                    [0, 0],
                    [-3.666, 0],
                    [0, -3.666],
                    [0, 0],
                    [3.666, 0],
                    [0, 3.667]
                  ],
                  "v": [
                    [22.408, 6.639],
                    [-22.407, 6.639],
                    [-29.046, -0.001],
                    [-22.407, -6.639],
                    [22.408, -6.639],
                    [29.046, -0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [29.296, 6.888], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 3,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "square",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [15.547, 15.548, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 57
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 67
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 72
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 140
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 145
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 0, 100],
              "t": 155
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 288
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 298
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 303
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 382
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 387
            },
            { "s": [0, 0, 100], "t": 397 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [35.462, -16.055, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 57
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 67
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 72
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 140
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 145
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [-90],
              "t": 155
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 288
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 298
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 303
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 382
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 387
            },
            { "s": [-90], "t": 397 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.887, 0],
                    [0, 0],
                    [0, 0.885],
                    [0, 0],
                    [-0.886, 0],
                    [0, 0],
                    [0, -0.886],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-0.886, 0],
                    [0, 0],
                    [0, -0.886],
                    [0, 0],
                    [0.887, 0],
                    [0, 0],
                    [0, 0.885]
                  ],
                  "v": [
                    [5.141, 6.745],
                    [-5.14, 6.745],
                    [-6.744, 5.141],
                    [-6.744, -5.14],
                    [-5.14, -6.745],
                    [5.141, -6.745],
                    [6.744, -5.14],
                    [6.744, 5.141]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.547, 15.547], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [3.44, 0],
                    [0, 0],
                    [0, 3.44],
                    [0, 0],
                    [-3.44, 0],
                    [0, 0],
                    [0, -3.44],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-3.44, 0],
                    [0, 0],
                    [0, -3.44],
                    [0, 0],
                    [3.44, 0],
                    [0, 0],
                    [0, 3.44]
                  ],
                  "v": [
                    [9.068, 15.298],
                    [-9.068, 15.298],
                    [-15.297, 9.069],
                    [-15.297, -9.068],
                    [-9.068, -15.298],
                    [9.068, -15.298],
                    [15.297, -9.068],
                    [15.297, 9.069]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.547, 15.548], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 4,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "triangle",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [15.547, 15.548, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 62
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 72
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 77
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 145
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [110, 110, 100],
              "t": 150
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0, 0, 100],
              "t": 160
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 298
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 308
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 313
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 392
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [110, 110, 100],
              "t": 397
            },
            { "s": [0, 0, 100], "t": 407 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-1.127, -16.055, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 62
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 72
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 77
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 145
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [5],
              "t": 150
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [-90],
              "t": 160
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 298
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 308
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 313
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 392
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [5],
              "t": 397
            },
            { "s": [-90], "t": 407 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [1.285, 0],
                    [0, 0],
                    [-0.575, 1.15],
                    [0, 0],
                    [-0.638, -1.275],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-1.286, 0],
                    [0, 0],
                    [0.638, -1.275],
                    [0, 0],
                    [0.575, 1.15]
                  ],
                  "v": [
                    [5.466, 7.354],
                    [-5.465, 7.354],
                    [-7.012, 4.85],
                    [-1.547, -6.079],
                    [1.548, -6.079],
                    [7.013, 4.85]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.547, 15.388], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [3.44, 0],
                    [0, 0],
                    [0, 3.44],
                    [0, 0],
                    [-3.44, 0],
                    [0, 0],
                    [0, -3.44],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-3.44, 0],
                    [0, 0],
                    [0, -3.44],
                    [0, 0],
                    [3.44, 0],
                    [0, 0],
                    [0, 3.44]
                  ],
                  "v": [
                    [9.068, 15.298],
                    [-9.068, 15.298],
                    [-15.297, 9.069],
                    [-15.297, -9.068],
                    [-9.068, -15.298],
                    [9.068, -15.298],
                    [15.297, -9.068],
                    [15.297, 9.069]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.547, 15.548], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 5,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "circle",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [15.548, 15.548, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 73
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 83
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 88
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 156
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 160
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 0, 100],
              "t": 170
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 305
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 315
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 320
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 399
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 403
            },
            { "s": [0, 0, 100], "t": 413 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-37.717, -16.055, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 73
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 83
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 88
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 156
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 160
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [-90],
              "t": 170
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 305
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 315
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 320
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 399
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 403
            },
            { "s": [-90], "t": 413 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -4.054],
                    [4.055, 0],
                    [0, 4.055],
                    [-4.055, 0]
                  ],
                  "o": [
                    [0, 4.055],
                    [-4.055, 0],
                    [0, -4.054],
                    [4.055, 0]
                  ],
                  "v": [
                    [7.341, 0],
                    [0, 7.341],
                    [-7.341, 0],
                    [0, -7.341]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.548, 15.547], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [3.44, 0],
                    [0, 0],
                    [0, 3.44],
                    [0, 0],
                    [-3.44, 0],
                    [0, 0],
                    [0, -3.44],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-3.44, 0],
                    [0, 0],
                    [0, -3.44],
                    [0, 0],
                    [3.44, 0],
                    [0, 0],
                    [0, 3.44]
                  ],
                  "v": [
                    [9.069, 15.298],
                    [-9.068, 15.298],
                    [-15.298, 9.069],
                    [-15.298, -9.068],
                    [-9.068, -15.298],
                    [9.069, -15.298],
                    [15.298, -9.068],
                    [15.298, 9.069]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.548, 15.548], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 6,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "menubar",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [4.081, 3.015, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 76
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 86
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 91
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 159
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 164
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 0, 100],
              "t": 174
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 324
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 334
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 339
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 418
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 423
            },
            { "s": [0, 0, 100], "t": 433 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-33.545, -79.921, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 76
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 86
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 91
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 159
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 164
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [-90],
              "t": 174
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 324
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 334
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 339
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 418
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 423
            },
            { "s": [-90], "t": 433 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [0.469, 5.561],
                    [7.693, 5.561]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.938, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [0.469, 3.015],
                    [7.693, 3.015]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.938, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [0.469, 0.469],
                    [7.693, 0.469]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.938, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 7,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "right icon mobile",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [4.829, 4.829, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 55
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 65
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 70
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 138
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 143
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 0, 100],
              "t": 153
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 306
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 316
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 321
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 400
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 405
            },
            { "s": [0, 0, 100], "t": 415 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [33.848, -78.383, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 55
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 65
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 70
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 138
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 143
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [-90],
              "t": 153
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 306
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 316
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 321
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 400
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 405
            },
            { "s": [-90], "t": 415 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -2.529],
                    [2.528, 0],
                    [0, 2.529],
                    [-2.529, 0]
                  ],
                  "o": [
                    [0, 2.529],
                    [-2.529, 0],
                    [0, -2.529],
                    [2.528, 0]
                  ],
                  "v": [
                    [4.579, 0],
                    [0, 4.579],
                    [-4.579, 0],
                    [0, -4.579]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [4.829, 4.829], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 8,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "bottom text mobile",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0.619, 0.623, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0, 100, 100],
              "t": 77
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [100, 100, 100],
              "t": 87
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 155
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 100, 100],
              "t": 165
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0, 100, 100],
              "t": 328
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [100, 100, 100],
              "t": 338
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 417
            },
            { "s": [0, 100, 100], "t": 427 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-10.748, -50.806, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.229, 0],
                    [0, 0],
                    [0, 0.23],
                    [0, 0],
                    [-0.229, 0],
                    [0, 0],
                    [0, -0.23],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-0.229, 0],
                    [0, 0],
                    [0, -0.23],
                    [0, 0],
                    [0.229, 0],
                    [0, 0],
                    [0, 0.23]
                  ],
                  "v": [
                    [10.924, 0.505],
                    [-10.923, 0.505],
                    [-11.339, 0.089],
                    [-11.339, -0.089],
                    [-10.923, -0.505],
                    [10.924, -0.505],
                    [11.339, -0.089],
                    [11.339, 0.089]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [11.59, 0.756], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 9,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "middle text mobile",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0.596, 0.489, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 100, 100],
              "t": 69
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 79
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 147
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 100, 100],
              "t": 157
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 100, 100],
              "t": 317
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 327
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 406
            },
            { "s": [0, 100, 100], "t": 416 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-16.134, -53.939, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.278, 0],
                    [0, 0],
                    [0, 0.279],
                    [-0.279, 0],
                    [0, 0],
                    [0, -0.279]
                  ],
                  "o": [
                    [0, 0],
                    [-0.279, 0],
                    [0, -0.279],
                    [0, 0],
                    [0.278, 0],
                    [0, 0.279]
                  ],
                  "v": [
                    [16.198, 0.505],
                    [-16.197, 0.505],
                    [-16.702, 0],
                    [-16.197, -0.505],
                    [16.198, -0.505],
                    [16.702, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [16.952, 0.755], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 10,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "top text mobile",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [0.463, 0.689, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 100, 100],
              "t": 62
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 72
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [100, 100, 100],
              "t": 140
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0, 100, 100],
              "t": 150
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 100, 100],
              "t": 306
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 316
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [100, 100, 100],
              "t": 395
            },
            { "s": [0, 100, 100], "t": 405 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-16.267, -56.74, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.278, 0],
                    [0, 0],
                    [0, 0.278],
                    [-0.279, 0],
                    [0, 0],
                    [0, -0.28]
                  ],
                  "o": [
                    [0, 0],
                    [-0.279, 0],
                    [0, -0.28],
                    [0, 0],
                    [0.278, 0],
                    [0, 0.278]
                  ],
                  "v": [
                    [16.198, 0.505],
                    [-16.197, 0.505],
                    [-16.702, 0.001],
                    [-16.197, -0.505],
                    [16.198, -0.505],
                    [16.702, 0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [16.952, 0.756], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 11,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "text background mobile",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 25,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [32.072, 50.201, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 47
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 57
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 62
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 130
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 135
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 0, 100],
              "t": 145
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0, 0, 100],
              "t": 293
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 303
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 308
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 387
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [110, 110, 100],
              "t": 392
            },
            { "s": [0, 0, 100], "t": 402 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0.223, -13.413, 0], "ix": 2 },
        "r": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 47
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 57
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 62
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 130
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 135
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [-90],
              "t": 145
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-90],
              "t": 293
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [5],
              "t": 303
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 308
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0],
              "t": 387
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [5],
              "t": 392
            },
            { "s": [-90], "t": 402 }
          ],
          "ix": 10
        },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [5.233, 0],
                    [0, 0],
                    [0, 5.233],
                    [0, 0],
                    [-5.233, 0],
                    [0, 0],
                    [0, -5.232],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-5.233, 0],
                    [0, 0],
                    [0, -5.232],
                    [0, 0],
                    [5.233, 0],
                    [0, 0],
                    [0, 5.233]
                  ],
                  "v": [
                    [22.347, 49.951],
                    [-22.347, 49.951],
                    [-31.822, 40.476],
                    [-31.822, -40.477],
                    [-22.347, -49.951],
                    [22.347, -49.951],
                    [31.822, -40.477],
                    [31.822, 40.476]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9608, 0.9529, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [32.072, 50.201], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 12,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "mobile",
      "sr": 1,
      "st": -3,
      "op": 477,
      "ip": -3,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [48.794, 93.409, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.157, "y": 0 },
              "i": { "x": 0, "y": 0.498 },
              "s": [0, 0, 100],
              "t": 26
            },
            {
              "o": { "x": 0.438, "y": -1.143 },
              "i": { "x": 0.802, "y": 1 },
              "s": [110, 110, 100],
              "t": 37
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.713, "y": 1 },
              "s": [100, 100, 100],
              "t": 42
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 193
            },
            {
              "o": { "x": 0.438, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 198
            },
            {
              "o": { "x": 0.157, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [0, 0, 100],
              "t": 208
            },
            {
              "o": { "x": 0.157, "y": 0 },
              "i": { "x": 0, "y": 0.498 },
              "s": [0, 0, 100],
              "t": 269
            },
            {
              "o": { "x": 0.438, "y": -1.143 },
              "i": { "x": 0.802, "y": 1 },
              "s": [110, 110, 100],
              "t": 280
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.713, "y": 1 },
              "s": [100, 100, 100],
              "t": 285
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [100, 100, 100],
              "t": 447
            },
            {
              "o": { "x": 0.438, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [110, 110, 100],
              "t": 452
            },
            { "s": [0, 0, 100], "t": 462 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0.222, 274.208, 0],
              "t": 26,
              "ti": [0, 47.167, 0],
              "to": [0, -47.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0, "y": 0 },
              "s": [0.222, -8.792, 0],
              "t": 37,
              "ti": [0, 0, 0],
              "to": [0, 0, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0.222, -8.792, 0],
              "t": 198,
              "ti": [0, -47.167, 0],
              "to": [0, 47.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0.833, "y": 0.833 },
              "s": [0.222, 274.208, 0],
              "t": 208,
              "ti": [0, 0, 0],
              "to": [0, 0, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0.222, 274.208, 0],
              "t": 269,
              "ti": [0, 47.167, 0],
              "to": [0, -47.167, 0]
            },
            {
              "o": { "x": 0.167, "y": 0.167 },
              "i": { "x": 0, "y": 0 },
              "s": [0.222, -8.792, 0],
              "t": 280,
              "ti": [0, 0, 0],
              "to": [0, 0, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [0.222, -8.792, 0],
              "t": 452,
              "ti": [0, -47.167, 0],
              "to": [0, 47.167, 0]
            },
            { "s": [0.222, 274.208, 0], "t": 462 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.117, 0],
                    [0, 0],
                    [0, 2.117],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.116, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.117]
                  ],
                  "v": [
                    [12.836, 2.333],
                    [-12.837, 2.333],
                    [-16.669, -1.5],
                    [-16.669, -2.333],
                    [16.669, -2.333],
                    [16.669, -1.5]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [48.794, 7.315], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [4.843, 0],
                    [0, 0],
                    [0, 4.843],
                    [0, 0],
                    [-4.843, 0],
                    [0, 0],
                    [0, -4.843],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-4.843, 0],
                    [0, 0],
                    [0, -4.843],
                    [0, 0],
                    [4.843, 0],
                    [0, 0],
                    [0, 4.843]
                  ],
                  "v": [
                    [35.043, 88.426],
                    [-35.042, 88.426],
                    [-43.811, 79.658],
                    [-43.811, -79.657],
                    [-35.042, -88.426],
                    [35.043, -88.426],
                    [43.811, -79.657],
                    [43.811, 79.658]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1.993, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [48.794, 93.409], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [4.843, 0],
                    [0, 0],
                    [0, 4.843],
                    [0, 0],
                    [-4.843, 0],
                    [0, 0],
                    [0, -4.843],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-4.843, 0],
                    [0, 0],
                    [0, -4.843],
                    [0, 0],
                    [4.843, 0],
                    [0, 0],
                    [0, 4.843]
                  ],
                  "v": [
                    [35.043, 88.426],
                    [-35.042, 88.426],
                    [-43.811, 79.658],
                    [-43.811, -79.657],
                    [-35.042, -88.426],
                    [35.043, -88.426],
                    [43.811, -79.657],
                    [43.811, 79.658]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [48.794, 93.409], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 13,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "mobile shadow",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 41,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [40.02, 69.41, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [10.739, 15.902, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [100],
              "t": 181
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [0],
              "t": 209
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [100],
              "t": 434
            },
            { "s": [0], "t": 462 }
          ],
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [4.424, 0],
                    [0, 0],
                    [0, 4.424],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-4.423, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 4.424]
                  ],
                  "v": [
                    [32.01, 69.41],
                    [-32.011, 69.41],
                    [-40.02, 61.399],
                    [-40.02, -69.409],
                    [40.02, -69.409],
                    [40.02, 61.399]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [40.02, 69.41], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 14,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "right moniotr text",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [53.164, 51.413, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [150.247, 22.177, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.798, -0.66],
                    [10.798, 0.66]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [85.165, 101.798], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-5.478, -0.335],
                    [5.478, 0.335]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [62.628, 100.421], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-13.843, -0.76],
                    [13.843, 0.76]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [88.21, 96.823], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-3.823, -0.21],
                    [3.823, 0.21]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [64.283, 95.51], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.798, -0.525],
                    [10.798, 0.525]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [85.165, 91.512], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-5.478, -0.266],
                    [5.478, 0.266]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [62.628, 90.418], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-15.796, -0.521],
                    [15.796, 0.521]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [90.163, 78.922], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-12.582, -0.415],
                    [12.582, 0.415]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.523, 77.78], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.37, -0.272],
                    [10.37, 0.272]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [95.588, 73.476], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-17.924, -0.47],
                    [17.924, 0.47]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [60.865, 72.566], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.127, -0.105],
                    [10.127, 0.105]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [85.835, 60.249], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-17.522, -0.182],
                    [17.522, 0.182]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [51.904, 59.898], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-13.928, -0.051],
                    [13.928, 0.051]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [82.034, 54.677], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-13.78, -0.05],
                    [13.78, 0.05]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [48.161, 54.555], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 15",
          "ix": 15,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.127, 0.032],
                    [10.127, -0.032]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [85.835, 49.132], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 16",
          "ix": 16,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-17.522, 0.055],
                    [17.522, -0.055]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [51.904, 49.24], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 17",
          "ix": 17,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-13.928, 0.139],
                    [13.928, -0.139]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [82.034, 43.611], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 18",
          "ix": 18,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-13.78, 0.137],
                    [13.78, -0.137]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [48.161, 43.949], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 19",
          "ix": 19,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.127, 0.17],
                    [10.127, -0.17]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [85.835, 38.013], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 20",
          "ix": 20,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-17.522, 0.294],
                    [17.522, -0.294]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [51.904, 38.582], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 21",
          "ix": 21,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-10.127, 0.238],
                    [10.127, -0.238]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [85.835, 32.454], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 22",
          "ix": 22,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-17.522, 0.413],
                    [17.522, -0.413]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [51.904, 33.253], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 23",
          "ix": 23,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-4.756, 0.144],
                    [4.756, -0.144]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [80.463, 27.058], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 24",
          "ix": 24,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-27.369, 0.83],
                    [27.369, -0.83]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.056, 28.223], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 25",
          "ix": 25,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-8.321, 0.298],
                    [8.321, -0.298]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [84.029, 22.431], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 26",
          "ix": 26,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-27.369, 0.981],
                    [27.369, -0.981]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.056, 23.936], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 27",
          "ix": 27,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-4.756, 0.197],
                    [4.756, -0.197]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [80.463, 18.059], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 28",
          "ix": 28,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-27.369, 1.132],
                    [27.369, -1.132]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.056, 19.648], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 29",
          "ix": 29,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-8.321, 0.39],
                    [8.321, -0.39]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [84.029, 13.391], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 30",
          "ix": 30,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-27.369, 1.283],
                    [27.369, -1.283]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.056, 15.36], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 31",
          "ix": 31,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-4.755, 0.249],
                    [4.755, -0.249]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [80.464, 9.059], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 32",
          "ix": 32,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-27.369, 1.435],
                    [27.369, -1.435]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.056, 11.073], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 33",
          "ix": 33,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-7.722, -0.307],
                    [7.722, 0.307]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [83.43, 84.243], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 34",
          "ix": 34,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-34.528, -1.373],
                    [34.528, 1.373]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.897, 82.313], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 35",
          "ix": 35,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-7.722, -0.133],
                    [7.722, 0.133]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [83.43, 65.767], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 36",
          "ix": 36,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-34.528, -0.593],
                    [34.528, 0.593]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.897, 64.935], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 37",
          "ix": 37,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-13.173, 0.809],
                    [13.173, -0.809]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [88.881, 1.178], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 38",
          "ix": 38,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-34.529, 2.123],
                    [34.529, -2.123]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.897, 4.496], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 39,
          "e": { "a": 0, "k": 100, "ix": 2 },
          "o": { "a": 0, "k": 90, "ix": 3 },
          "s": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0.214, "y": 0 },
                "i": { "x": 0, "y": 1.064 },
                "s": [100],
                "t": 100
              },
              { "s": [0], "t": 281 }
            ],
            "ix": 1
          },
          "m": 2
        }
      ],
      "ind": 15,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 38 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [65.689, 83.034, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [146.42, 19.908, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.642, 0.247],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.653],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.642, -0.247],
                    [0, 0],
                    [0, 2.652]
                  ],
                  "v": [
                    [60.517, 82.537],
                    [-65.439, 70.77],
                    [-65.439, -70.771],
                    [60.517, -82.537],
                    [65.439, -78.052],
                    [65.439, 78.053]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [65.689, 83.034], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 16,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 39 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [68.206, 86.212, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [146.943, 19.908, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.642, 0.248],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.654],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.642, -0.249],
                    [0, 0],
                    [0, 2.654]
                  ],
                  "v": [
                    [63.031, 85.714],
                    [-67.956, 73.415],
                    [-67.956, -73.415],
                    [63.031, -85.713],
                    [67.956, -81.229],
                    [67.956, 81.23]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.206, 86.212], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 17,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 40 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [68.206, 86.212, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [150.248, 19.908, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [2.643, 0.248],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.654],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.643, -0.249],
                    [0, 0],
                    [0, 2.654]
                  ],
                  "v": [
                    [63.031, 85.714],
                    [-67.956, 73.415],
                    [-67.956, -73.415],
                    [63.031, -85.713],
                    [67.956, -81.229],
                    [67.956, 81.23]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.206, 86.212], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 18,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 45 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.024, 23.995, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [133.197, 79.635, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-8.773, 21.647],
                    [8.773, 23.745],
                    [8.773, -23.745],
                    [-8.773, -23.745]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8118, 0.7922, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 23.995], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 19,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 46 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.024, 31.593, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [133.197, 89.177, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-8.773, 28.381],
                    [8.773, 31.343],
                    [8.773, -31.343],
                    [-8.773, -31.343]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9059, 0.8902, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 31.593], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 20,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 47 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.776, 3.053, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [142.51, 117.717, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [2.526, 1.167],
                    [-0.54, 2.803],
                    [-2.526, 0.265],
                    [-1.536, -2.803],
                    [1.629, -0.357]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.776, 3.053], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 21,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 41 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.023, 31.27, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [136.263, 87.864, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-8.773, 27.929],
                    [8.773, 31.02],
                    [8.773, -31.02],
                    [-8.773, -31.02]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 31.27], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 22,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 42 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [31.039, 6.815, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [133.915, 118.62, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-30.789, -3.117],
                    [25.334, 6.565],
                    [30.789, 3.115],
                    [-25.333, -6.565]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9059, 0.8902, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [31.039, 6.815], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 23,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 43 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.356, 1.441, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [162.598, 122.927, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [2.106, -1.191],
                    [2.106, 1.012],
                    [-0.326, 1.191],
                    [-2.106, 0.993],
                    [-1.612, -0.326]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.356, 1.442], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 24,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 44 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.556, 1.534, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [105.434, 116.434, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-2.306, 1.273],
                    [-2.306, -0.93],
                    [1.779, -1.284],
                    [2.306, 1.284]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.556, 1.534], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 25,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 8 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [31.039, 6.816, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [133.917, 120.823, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-30.789, -3.117],
                    [25.334, 6.566],
                    [30.789, 3.115],
                    [-25.334, -6.566]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [31.039, 6.816], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 26,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "left monitor text",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [53.163, 51.413, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-150.247, 22.177, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.798, -0.66],
                    [-10.798, 0.66]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [21.162, 101.798], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.478, -0.335],
                    [-5.478, 0.335]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [43.699, 100.421], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [13.844, -0.76],
                    [-13.844, 0.76]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [18.116, 96.823], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.823, -0.21],
                    [-3.823, 0.21]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.044, 95.51], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.798, -0.525],
                    [-10.798, 0.525]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [21.162, 91.512], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.478, -0.266],
                    [-5.478, 0.266]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [43.699, 90.418], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [15.796, -0.521],
                    [-15.796, 0.521]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [16.164, 78.922], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [12.583, -0.415],
                    [-12.583, 0.415]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [50.804, 77.78], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.37, -0.272],
                    [-10.37, 0.272]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [10.738, 73.476], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [17.924, -0.47],
                    [-17.924, 0.47]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [45.462, 72.566], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.128, -0.105],
                    [-10.128, 0.105]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [20.491, 60.249], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [17.522, -0.182],
                    [-17.522, 0.182]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [54.423, 59.898], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [13.928, -0.051],
                    [-13.928, 0.051]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [24.293, 54.677], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [13.78, -0.05],
                    [-13.78, 0.05]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [58.165, 54.555], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 15",
          "ix": 15,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.128, 0.032],
                    [-10.128, -0.032]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [20.491, 49.132], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 16",
          "ix": 16,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [17.522, 0.055],
                    [-17.522, -0.055]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [54.423, 49.24], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 17",
          "ix": 17,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [13.928, 0.139],
                    [-13.928, -0.139]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [24.293, 43.611], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 18",
          "ix": 18,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [13.78, 0.137],
                    [-13.78, -0.137]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [58.165, 43.949], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 19",
          "ix": 19,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.128, 0.17],
                    [-10.128, -0.17]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [20.491, 38.013], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 20",
          "ix": 20,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [17.522, 0.294],
                    [-17.522, -0.294]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [54.423, 38.582], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 21",
          "ix": 21,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.128, 0.238],
                    [-10.128, -0.238]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [20.491, 32.454], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 22",
          "ix": 22,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [17.522, 0.413],
                    [-17.522, -0.413]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [54.423, 33.253], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 23",
          "ix": 23,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.756, 0.144],
                    [-4.756, -0.144]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.863, 27.058], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 24",
          "ix": 24,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.37, 0.83],
                    [-27.37, -0.83]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [64.271, 28.223], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 25",
          "ix": 25,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.322, 0.298],
                    [-8.322, -0.298]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [22.297, 22.431], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 26",
          "ix": 26,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.37, 0.981],
                    [-27.37, -0.981]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [64.271, 23.936], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 27",
          "ix": 27,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.756, 0.197],
                    [-4.756, -0.197]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.863, 18.059], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 28",
          "ix": 28,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.37, 1.132],
                    [-27.37, -1.132]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [64.271, 19.648], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 29",
          "ix": 29,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.322, 0.39],
                    [-8.322, -0.39]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [22.297, 13.391], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 30",
          "ix": 30,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.37, 1.283],
                    [-27.37, -1.283]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [64.271, 15.36], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 31",
          "ix": 31,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.756, 0.249],
                    [-4.756, -0.249]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.863, 9.059], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 32",
          "ix": 32,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.369, 1.435],
                    [-27.369, -1.435]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [64.27, 11.073], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 33",
          "ix": 33,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [7.722, -0.307],
                    [-7.722, 0.307]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [22.897, 84.243], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 34",
          "ix": 34,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [34.529, -1.373],
                    [-34.529, 1.373]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [71.429, 82.313], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 35",
          "ix": 35,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [7.723, -0.133],
                    [-7.723, 0.133]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [22.896, 65.767], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 36",
          "ix": 36,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [34.529, -0.593],
                    [-34.529, 0.593]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [71.429, 64.935], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 37",
          "ix": 37,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [13.174, 0.809],
                    [-13.174, -0.809]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [17.445, 1.178], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 38",
          "ix": 38,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [34.529, 2.123],
                    [-34.529, -2.123]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [71.429, 4.496], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 39,
          "e": { "a": 0, "k": 100, "ix": 2 },
          "o": { "a": 0, "k": 90, "ix": 3 },
          "s": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0.333, "y": 0 },
                "i": { "x": 0.667, "y": 1 },
                "s": [100],
                "t": 2
              },
              { "s": [0], "t": 191 }
            ],
            "ix": 1
          },
          "m": 2
        }
      ],
      "ind": 27,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 49 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [65.69, 83.034, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-146.42, 19.908, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-2.642, 0.247],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.653],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.642, -0.247],
                    [0, 0],
                    [0, 2.652]
                  ],
                  "v": [
                    [-60.517, 82.537],
                    [65.44, 70.77],
                    [65.44, -70.771],
                    [-60.517, -82.537],
                    [-65.44, -78.052],
                    [-65.44, 78.053]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [65.69, 83.034], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 28,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 50 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [68.206, 86.212, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-146.942, 19.908, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-2.643, 0.248],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.654],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.643, -0.249],
                    [0, 0],
                    [0, 2.654]
                  ],
                  "v": [
                    [-63.031, 85.714],
                    [67.956, 73.415],
                    [67.956, -73.415],
                    [-63.031, -85.713],
                    [-67.956, -81.229],
                    [-67.956, 81.23]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.206, 86.212], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 29,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 51 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [68.206, 86.212, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-150.248, 19.908, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-2.642, 0.248],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.654],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.642, -0.249],
                    [0, 0],
                    [0, 2.654]
                  ],
                  "v": [
                    [-63.031, 85.714],
                    [67.956, 73.415],
                    [67.956, -73.415],
                    [-63.031, -85.713],
                    [-67.956, -81.229],
                    [-67.956, 81.23]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.206, 86.212], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 30,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 53 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.023, 23.995, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-133.197, 79.635, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.773, 21.647],
                    [-8.773, 23.745],
                    [-8.773, -23.745],
                    [8.773, -23.745]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8118, 0.7922, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 23.995], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 31,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 54 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.023, 31.593, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-133.197, 89.177, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.773, 28.381],
                    [-8.773, 31.343],
                    [-8.773, -31.343],
                    [8.773, -31.343]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9059, 0.8902, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 31.593], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 32,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 55 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.776, 3.053, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-142.51, 117.717, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-2.525, 1.167],
                    [0.539, 2.803],
                    [2.525, 0.265],
                    [1.536, -2.803],
                    [-1.63, -0.357]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.776, 3.053], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 33,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 52 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.023, 31.27, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-136.262, 87.864, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.773, 27.929],
                    [-8.773, 31.02],
                    [-8.773, -31.02],
                    [8.773, -31.02]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 31.27], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 34,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 56 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [31.039, 6.815, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-133.916, 118.62, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [30.789, -3.117],
                    [-25.333, 6.565],
                    [-30.789, 3.115],
                    [25.334, -6.565]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9059, 0.8902, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [31.039, 6.815], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 35,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 57 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.356, 1.441, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-162.598, 122.927, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-2.107, -1.191],
                    [-2.107, 1.012],
                    [0.327, 1.191],
                    [2.107, 0.993],
                    [1.613, -0.326]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.356, 1.442], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 36,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 58 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.557, 1.534, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-105.434, 116.434, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [2.307, 1.273],
                    [2.307, -0.93],
                    [-1.78, -1.284],
                    [-2.307, 1.284]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.557, 1.534], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 37,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 9 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [31.039, 6.816, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-133.917, 120.823, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [30.789, -3.117],
                    [-25.334, 6.566],
                    [-30.789, 3.115],
                    [25.333, -6.566]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [31.039, 6.816], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 38,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "middle monitor text",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [67.904, 52.453, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0.694, 14.708, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [28.255, 32.429],
                    [8.962, 32.429]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [44.39, 32.429],
                    [34.042, 32.429]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [28.255, 36.417],
                    [3.701, 36.417]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [41.233, 36.417],
                    [34.043, 36.417]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [28.255, 40.405],
                    [8.962, 40.405]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [44.39, 40.405],
                    [34.042, 40.405]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [28.255, 50.297],
                    [0.368, 50.297]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [58.246, 50.297],
                    [34.043, 50.297]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.433, 54.604],
                    [0.369, 54.604]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [58.246, 54.604],
                    [24.221, 54.604]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 64.654],
                    [8.963, 64.654]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [66.839, 64.654],
                    [32.814, 64.654]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [34.043, 68.962],
                    [8.963, 68.962]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [66.839, 68.962],
                    [39.829, 68.962]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 15",
          "ix": 15,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 73.269],
                    [8.963, 73.269]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 16",
          "ix": 16,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [66.839, 73.269],
                    [32.814, 73.269]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 17",
          "ix": 17,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [34.043, 77.577],
                    [8.963, 77.577]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 18",
          "ix": 18,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [66.839, 77.577],
                    [39.829, 77.577]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 19",
          "ix": 19,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 81.885],
                    [8.963, 81.885]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 20",
          "ix": 20,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [66.839, 81.885],
                    [32.814, 81.885]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 21",
          "ix": 21,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 86.191],
                    [8.963, 86.191]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 22",
          "ix": 22,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [66.839, 86.191],
                    [32.814, 86.191]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 23",
          "ix": 23,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 94.009],
                    [12.119, 94.009]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 24",
          "ix": 24,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [87.359, 94.009],
                    [32.814, 94.009]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 25",
          "ix": 25,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 97.518],
                    [18.433, 97.518]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 26",
          "ix": 26,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [87.359, 97.518],
                    [32.814, 97.518]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 27",
          "ix": 27,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 101.028],
                    [12.119, 101.028]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 28",
          "ix": 28,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [87.359, 101.028],
                    [32.814, 101.028]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 29",
          "ix": 29,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 104.538],
                    [18.433, 104.538]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 30",
          "ix": 30,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [87.359, 104.538],
                    [32.814, 104.538]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 31",
          "ix": 31,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 20.464],
                    [3.701, 20.464]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 32",
          "ix": 32,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [102.968, 20.464],
                    [32.814, 20.464]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 33",
          "ix": 33,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.027, 60.347],
                    [13.172, 60.347]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 34",
          "ix": 34,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [102.968, 60.347],
                    [32.814, 60.347]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 35",
          "ix": 35,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [107.554, 72.477],
                    [126.846, 72.477]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 36",
          "ix": 36,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [91.419, 72.477],
                    [101.767, 72.477]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 37",
          "ix": 37,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [107.554, 68.489],
                    [132.108, 68.489]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 38",
          "ix": 38,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [94.576, 68.489],
                    [101.766, 68.489]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 39",
          "ix": 39,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [107.554, 64.501],
                    [126.846, 64.501]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 40",
          "ix": 40,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [91.419, 64.501],
                    [101.767, 64.501]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 41",
          "ix": 41,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [107.554, 54.61],
                    [135.44, 54.61]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 42",
          "ix": 42,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [77.563, 54.61],
                    [101.766, 54.61]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 43",
          "ix": 43,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [117.376, 50.303],
                    [135.44, 50.303]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 44",
          "ix": 44,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [77.563, 50.303],
                    [111.587, 50.303]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 45",
          "ix": 45,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 40.252],
                    [126.846, 40.252]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 46",
          "ix": 46,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [68.969, 40.252],
                    [102.993, 40.252]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 47",
          "ix": 47,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [101.766, 35.944],
                    [126.846, 35.944]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 48",
          "ix": 48,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [68.969, 35.944],
                    [95.978, 35.944]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 49",
          "ix": 49,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 31.637],
                    [126.846, 31.637]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 50",
          "ix": 50,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [68.969, 31.637],
                    [102.993, 31.637]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 51",
          "ix": 51,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [101.766, 27.329],
                    [126.846, 27.329]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.9137, 0.8941, 0.8941], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 52",
          "ix": 52,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [68.969, 27.329],
                    [95.978, 27.329]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 53",
          "ix": 53,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 23.022],
                    [126.846, 23.022]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 54",
          "ix": 54,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [68.969, 23.022],
                    [102.993, 23.022]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 55",
          "ix": 55,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 18.715],
                    [126.846, 18.715]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 56",
          "ix": 56,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [68.969, 18.715],
                    [102.993, 18.715]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 57",
          "ix": 57,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 10.898],
                    [123.689, 10.898]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 58",
          "ix": 58,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [48.449, 10.898],
                    [102.994, 10.898]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 59",
          "ix": 59,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 7.388],
                    [117.376, 7.388]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 60",
          "ix": 60,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [48.449, 7.388],
                    [102.994, 7.388]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 61",
          "ix": 61,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 3.878],
                    [123.689, 3.878]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 62",
          "ix": 62,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [48.449, 3.878],
                    [102.994, 3.878]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 63",
          "ix": 63,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 0.368],
                    [117.376, 0.368]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 64",
          "ix": 64,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [48.449, 0.368],
                    [102.994, 0.368]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 65",
          "ix": 65,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 84.442],
                    [132.108, 84.442]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 66",
          "ix": 66,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [32.839, 84.442],
                    [102.993, 84.442]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 67",
          "ix": 67,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [108.782, 44.559],
                    [122.637, 44.559]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 68",
          "ix": 68,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [32.839, 44.559],
                    [102.993, 44.559]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.737, "ix": 5 },
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 69,
          "e": { "a": 0, "k": 100, "ix": 2 },
          "o": { "a": 0, "k": 90, "ix": 3 },
          "s": {
            "a": 1,
            "k": [
              {
                "o": { "x": 0.333, "y": 0 },
                "i": { "x": 0, "y": 0.922 },
                "s": [100],
                "t": 39
              },
              { "s": [0], "t": 217 }
            ],
            "ix": 1
          },
          "m": 2
        }
      ],
      "ind": 39,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 11 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [76.918, 71.021, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 19.909, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [76.668, 70.771],
                    [-76.668, 70.771],
                    [-76.668, -70.771],
                    [76.668, -70.771]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [76.918, 71.021], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 40,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 12 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [79.237, 73.665, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 19.908, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [78.987, 73.415],
                    [-78.987, 73.415],
                    [-78.987, -73.415],
                    [78.987, -73.415]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.2588, 0.2627, 0.2745], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [79.237, 73.665], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 41,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 59 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [34.344, 1.882, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 104.221, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [29.509, -1.632],
                    [-29.509, -1.632],
                    [-34.094, 1.632],
                    [34.094, 1.632]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9059, 0.8902, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.344, 1.882], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 42,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 60 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [34.344, 1.569, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 107.171, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [34.094, 1.319],
                    [-34.094, 1.319],
                    [-34.094, -1.319],
                    [34.094, -1.319]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7098, 0.6706, 0.9373], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [34.344, 1.569], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 43,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 61 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.023, 14.503, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 83.465, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.773, 14.253],
                    [-8.773, 14.253],
                    [-8.773, -14.253],
                    [8.773, -14.253]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8118, 0.7922, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 14.503], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 44,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 13 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [9.023, 19.56, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 88.521, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [8.773, 19.31],
                    [-8.773, 19.31],
                    [-8.773, -19.31],
                    [8.773, -19.31]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.9059, 0.8902, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [9.023, 19.56], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 45,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 14 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [47.331, 10.92, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [134.699, 120.33, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -3.185],
                    [-26.002, -4.134],
                    [0, 3.184],
                    [26.002, 4.134]
                  ],
                  "o": [
                    [0, 3.185],
                    [26.002, 4.134],
                    [0, -3.185],
                    [-26.002, -4.134]
                  ],
                  "v": [
                    [-47.08, -7.485],
                    [-0.001, 5.767],
                    [47.08, 7.486],
                    [-0.001, -5.766]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [47.331, 10.92], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 46,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 15 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [47.33, 10.92, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-134.698, 120.33, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -3.185],
                    [26.002, -4.134],
                    [0, 3.184],
                    [-26.001, 4.134]
                  ],
                  "o": [
                    [0, 3.185],
                    [-26.001, 4.134],
                    [0, -3.185],
                    [26.002, -4.134]
                  ],
                  "v": [
                    [47.081, -7.485],
                    [-0.001, 5.767],
                    [-47.081, 7.486],
                    [-0.001, -5.766]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [47.33, 10.92], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 47,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 16 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [64.021, 6.017, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-3.431, 108.49, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -3.185],
                    [35.219, 0],
                    [0, 3.186],
                    [-35.221, 0]
                  ],
                  "o": [
                    [0, 3.186],
                    [-35.221, 0],
                    [0, -3.185],
                    [35.219, 0]
                  ],
                  "v": [
                    [63.771, 0],
                    [0, 5.767],
                    [-63.771, 0],
                    [0, -5.767]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [64.021, 6.017], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 48,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 25 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [74.616, 8.886, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0.651, 126.333, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.667, 1.214],
                    [-4.905, 1.214],
                    [-6.667, -1.214],
                    [4.557, -1.214]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [138.284, 12.875], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.451, 1.214],
                    [-5.121, 1.214],
                    [-6.451, -1.214],
                    [4.773, -1.214]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [124.092, 12.875], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [41.803, 1.214],
                    [-41.803, 1.214],
                    [-40.542, -1.214],
                    [40.558, -1.214]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [74.333, 12.875], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.112, 1.214],
                    [-6.459, 1.214],
                    [-4.766, -1.214],
                    [6.459, -1.214]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [24.581, 12.875], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.897, 1.214],
                    [-6.675, 1.214],
                    [-4.549, -1.214],
                    [6.675, -1.214]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [10.39, 12.875], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.374, 1.122],
                    [-4.745, 1.122],
                    [-6.374, -1.122],
                    [4.425, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [135.827, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 7",
          "ix": 7,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.174, 1.122],
                    [-4.944, 1.122],
                    [-6.174, -1.122],
                    [4.624, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [122.182, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 8",
          "ix": 8,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.974, 1.122],
                    [-5.144, 1.122],
                    [-5.974, -1.122],
                    [4.824, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [108.539, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 9",
          "ix": 9,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.775, 1.122],
                    [-5.344, 1.122],
                    [-5.775, -1.122],
                    [5.024, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [94.894, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 10",
          "ix": 10,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.575, 1.122],
                    [-5.544, 1.122],
                    [-5.575, -1.122],
                    [5.222, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [81.25, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 11",
          "ix": 11,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.535, 1.122],
                    [-5.583, 1.122],
                    [-5.215, -1.122],
                    [5.583, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [67.445, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 12",
          "ix": 12,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.336, 1.122],
                    [-5.782, 1.122],
                    [-5.016, -1.122],
                    [5.782, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [53.801, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 13",
          "ix": 13,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.137, 1.122],
                    [-5.982, 1.122],
                    [-4.816, -1.122],
                    [5.982, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [40.156, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 14",
          "ix": 14,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.937, 1.122],
                    [-6.182, 1.122],
                    [-4.617, -1.122],
                    [6.182, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [26.512, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 15",
          "ix": 15,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.737, 1.122],
                    [-6.382, 1.122],
                    [-4.416, -1.122],
                    [6.382, -1.122]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [12.868, 9.803], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 16",
          "ix": 16,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.106, 1.04],
                    [-4.596, 1.04],
                    [-6.106, -1.04],
                    [4.298, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [133.554, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 17",
          "ix": 17,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.921, 1.04],
                    [-4.781, 1.04],
                    [-5.921, -1.04],
                    [4.483, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [120.415, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 18",
          "ix": 18,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.736, 1.04],
                    [-4.966, 1.04],
                    [-5.736, -1.04],
                    [4.668, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [107.277, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 19",
          "ix": 19,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.551, 1.04],
                    [-5.151, 1.04],
                    [-5.551, -1.04],
                    [4.853, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [94.139, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 20",
          "ix": 20,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.365, 1.04],
                    [-5.335, 1.04],
                    [-5.365, -1.04],
                    [5.039, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [81, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 21",
          "ix": 21,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.328, 1.04],
                    [-5.372, 1.04],
                    [-5.031, -1.04],
                    [5.372, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [67.714, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 22",
          "ix": 22,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.143, 1.04],
                    [-5.557, 1.04],
                    [-4.846, -1.04],
                    [5.557, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [54.576, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 23",
          "ix": 23,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.958, 1.04],
                    [-5.742, 1.04],
                    [-4.661, -1.04],
                    [5.742, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [41.437, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 24",
          "ix": 24,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.773, 1.04],
                    [-5.927, 1.04],
                    [-4.475, -1.04],
                    [5.927, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [28.299, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 25",
          "ix": 25,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.588, 1.04],
                    [-6.113, 1.04],
                    [-4.29, -1.04],
                    [6.113, -1.04]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.161, 6.959], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 26",
          "ix": 26,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.859, 0.967],
                    [-4.455, 0.967],
                    [-5.859, -0.967],
                    [4.177, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [131.442, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 27",
          "ix": 27,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.687, 0.967],
                    [-4.627, 0.967],
                    [-5.687, -0.967],
                    [4.35, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [118.774, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 28",
          "ix": 28,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.514, 0.967],
                    [-4.799, 0.967],
                    [-5.514, -0.967],
                    [4.522, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [106.106, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 29",
          "ix": 29,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.343, 0.967],
                    [-4.971, 0.967],
                    [-5.343, -0.967],
                    [4.695, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [93.438, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 30",
          "ix": 30,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.17, 0.967],
                    [-5.143, 0.967],
                    [-5.17, -0.967],
                    [4.866, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [80.77, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 31",
          "ix": 31,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.137, 0.967],
                    [-5.176, 0.967],
                    [-4.859, -0.967],
                    [5.176, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [67.963, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 32",
          "ix": 32,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.964, 0.967],
                    [-5.35, 0.967],
                    [-4.688, -0.967],
                    [5.35, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.295, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 33",
          "ix": 33,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.792, 0.967],
                    [-5.521, 0.967],
                    [-4.516, -0.967],
                    [5.521, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.627, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 34",
          "ix": 34,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.62, 0.967],
                    [-5.693, 0.967],
                    [-4.344, -0.967],
                    [5.693, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [29.959, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 35",
          "ix": 35,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.448, 0.967],
                    [-5.866, 0.967],
                    [-4.172, -0.967],
                    [5.866, -0.967]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [17.291, 4.318], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 36",
          "ix": 36,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.631, 0.902],
                    [-4.322, 0.902],
                    [-5.631, -0.902],
                    [4.064, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [129.478, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 37",
          "ix": 37,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.47, 0.902],
                    [-4.483, 0.902],
                    [-5.47, -0.902],
                    [4.224, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [117.247, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 38",
          "ix": 38,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.31, 0.902],
                    [-4.643, 0.902],
                    [-5.31, -0.902],
                    [4.384, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [105.017, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 39",
          "ix": 39,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.149, 0.902],
                    [-4.803, 0.902],
                    [-5.149, -0.902],
                    [4.545, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [92.786, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 40",
          "ix": 40,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.989, 0.902],
                    [-4.963, 0.902],
                    [-4.989, -0.902],
                    [4.706, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [80.555, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 41",
          "ix": 41,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.957, 0.902],
                    [-4.995, 0.902],
                    [-4.699, -0.902],
                    [4.995, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [68.195, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 42",
          "ix": 42,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.797, 0.902],
                    [-5.155, 0.902],
                    [-4.539, -0.902],
                    [5.155, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.965, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 43",
          "ix": 43,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.636, 0.902],
                    [-5.316, 0.902],
                    [-4.379, -0.902],
                    [5.316, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [43.734, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 44",
          "ix": 44,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.476, 0.902],
                    [-5.476, 0.902],
                    [-4.218, -0.902],
                    [5.476, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [31.503, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 45",
          "ix": 45,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.315, 0.902],
                    [-5.636, 0.902],
                    [-4.058, -0.902],
                    [5.636, -0.902]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.8824, 0.8549, 0.9176], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [19.273, 1.86], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 46",
          "ix": 46,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [74.366, 7.581],
                    [-74.366, 7.581],
                    [-60.661, -7.581],
                    [60.661, -7.581]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [74.616, 7.831], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 47",
          "ix": 47,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-2.21, 1.717],
                    [-2.21, -0.393],
                    [2.21, -1.717],
                    [2.21, -0.001],
                    [-0.413, 0.937]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7961, 0.749, 0.8667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.46, 15.805], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 48",
          "ix": 48,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [2.016, 1.386],
                    [2.016, -0.723],
                    [-2.016, -1.386],
                    [-2.016, 0.217]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7961, 0.749, 0.8667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [146.966, 16.135], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 49",
          "ix": 49,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [74.366, 7.581],
                    [-74.366, 7.581],
                    [-60.661, -7.581],
                    [60.661, -7.581]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.7961, 0.749, 0.8667], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [74.616, 9.94], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 49,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 17 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [206.401, 24.26, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 123.956, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [206.151, 20.384],
                    [-206.151, 20.384],
                    [-161.398, -20.384],
                    [163.211, -20.384]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [206.401, 20.634], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-7.495, 4.154],
                    [-7.495, -3.098],
                    [7.495, -4.154],
                    [6.441, 0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [7.746, 44.116], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [7.495, 4.154],
                    [7.495, -3.098],
                    [-7.495, -4.154],
                    [-6.441, 0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [405.057, 44.116], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [206.151, 20.837],
                    [-206.151, 20.837],
                    [-162.304, -20.837],
                    [162.304, -20.837]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [206.401, 27.433], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 50,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 62 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [4.981, 4.981, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-157.544, -105.397, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.37],
                    [1.37, 0],
                    [0, 1.37],
                    [-1.371, 0]
                  ],
                  "o": [
                    [0, 1.37],
                    [-1.371, 0],
                    [0, -1.37],
                    [1.37, 0]
                  ],
                  "v": [
                    [2.481, 0],
                    [0.001, 2.481],
                    [-2.481, 0],
                    [0.001, -2.481]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [4.981, 4.981], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 51,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 63 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [4.981, 4.981, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [150.248, -78.383, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.37],
                    [1.37, 0],
                    [0, 1.37],
                    [-1.371, 0]
                  ],
                  "o": [
                    [0, 1.37],
                    [-1.371, 0],
                    [0, -1.37],
                    [1.37, 0]
                  ],
                  "v": [
                    [2.481, 0],
                    [0, 2.481],
                    [-2.481, 0],
                    [0, -2.481]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [4.981, 4.981], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 52,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 64 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [6.573, 6.573, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-95.369, 160.577, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -2.25],
                    [2.249, 0],
                    [0, 2.249],
                    [-2.25, 0]
                  ],
                  "o": [
                    [0, 2.249],
                    [-2.25, 0],
                    [0, -2.25],
                    [2.249, 0]
                  ],
                  "v": [
                    [4.073, 0],
                    [0, 4.073],
                    [-4.073, 0],
                    [0, -4.073]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1529, 0.1529], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [6.573, 6.573], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 53,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 65 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [1.767, 1.767, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-195.622, 114.257, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -0.837],
                    [0.837, 0],
                    [0, 0.838],
                    [-0.837, 0]
                  ],
                  "o": [
                    [0, 0.838],
                    [-0.837, 0],
                    [0, -0.837],
                    [0.837, 0]
                  ],
                  "v": [
                    [1.517, 0],
                    [0, 1.517],
                    [-1.517, 0],
                    [0, -1.517]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [1.767, 1.767], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 54,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 66 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [1.767, 1.768, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [91.898, 164.65, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -0.838],
                    [0.838, 0],
                    [0, 0.837],
                    [-0.837, 0]
                  ],
                  "o": [
                    [0, 0.837],
                    [-0.837, 0],
                    [0, -0.838],
                    [0.838, 0]
                  ],
                  "v": [
                    [1.517, 0],
                    [-0.001, 1.518],
                    [-1.517, 0],
                    [-0.001, -1.518]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [1.766, 1.768], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 55,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 67 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [1.766, 1.767, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [107.741, -147.344, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -0.837],
                    [0.838, 0],
                    [0, 0.838],
                    [-0.837, 0]
                  ],
                  "o": [
                    [0, 0.838],
                    [-0.837, 0],
                    [0, -0.837],
                    [0.838, 0]
                  ],
                  "v": [
                    [1.517, 0],
                    [-0.001, 1.517],
                    [-1.517, 0],
                    [-0.001, -1.517]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [1.766, 1.767], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 56,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 68 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [1.767, 1.767, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-108.597, 156.105, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -0.837],
                    [0.838, 0],
                    [0, 0.838],
                    [-0.837, 0]
                  ],
                  "o": [
                    [0, 0.838],
                    [-0.837, 0],
                    [0, -0.837],
                    [0.838, 0]
                  ],
                  "v": [
                    [1.517, 0],
                    [-0.001, 1.517],
                    [-1.517, 0],
                    [-0.001, -1.517]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [1.767, 1.767], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 57,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 69 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [1.767, 1.767, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [160.541, -72.287, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -0.838],
                    [0.838, 0],
                    [0, 0.837],
                    [-0.837, 0]
                  ],
                  "o": [
                    [0, 0.837],
                    [-0.837, 0],
                    [0, -0.838],
                    [0.838, 0]
                  ],
                  "v": [
                    [1.517, 0],
                    [-0.001, 1.517],
                    [-1.517, 0],
                    [-0.001, -1.517]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [1.766, 1.767], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 58,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 70 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.584, 2.583, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-110.931, -124.847, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.289],
                    [1.288, 0],
                    [0, 1.289],
                    [-1.289, 0]
                  ],
                  "o": [
                    [0, 1.289],
                    [-1.289, 0],
                    [0, -1.289],
                    [1.288, 0]
                  ],
                  "v": [
                    [2.334, 0],
                    [0.001, 2.333],
                    [-2.334, 0],
                    [0.001, -2.333]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.584, 2.583], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 59,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 71 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [6.573, 6.573, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [109.567, -130.11, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -2.25],
                    [2.25, 0],
                    [0, 2.25],
                    [-2.249, 0]
                  ],
                  "o": [
                    [0, 2.25],
                    [-2.249, 0],
                    [0, -2.25],
                    [2.25, 0]
                  ],
                  "v": [
                    [4.073, 0],
                    [0, 4.073],
                    [-4.073, 0],
                    [0, -4.073]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [6.573, 6.573], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 60,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 72 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [3.381, 3.381, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [105.434, 157.719, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.729],
                    [1.729, 0],
                    [0, 1.729],
                    [-1.729, 0]
                  ],
                  "o": [
                    [0, 1.729],
                    [-1.729, 0],
                    [0, -1.729],
                    [1.729, 0]
                  ],
                  "v": [
                    [3.131, 0.001],
                    [0.001, 3.131],
                    [-3.131, 0.001],
                    [0.001, -3.131]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [3.38, 3.381], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 61,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 73 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.135, 2.134, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-206.151, 127.129, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.04],
                    [1.041, 0],
                    [0, 1.041],
                    [-1.041, 0]
                  ],
                  "o": [
                    [0, 1.041],
                    [-1.041, 0],
                    [0, -1.04],
                    [1.041, 0]
                  ],
                  "v": [
                    [1.885, -0.001],
                    [0, 1.885],
                    [-1.885, -0.001],
                    [0, -1.885]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.3333, 0.098, 0.5451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.135, 2.134], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 62,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 74 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [2.134, 2.135, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-135.945, 156.473, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.041],
                    [1.04, 0],
                    [0, 1.041],
                    [-1.041, 0]
                  ],
                  "o": [
                    [0, 1.041],
                    [-1.041, 0],
                    [0, -1.041],
                    [1.04, 0]
                  ],
                  "v": [
                    [1.885, 0],
                    [0.001, 1.885],
                    [-1.885, 0],
                    [0.001, -1.885]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [2.134, 2.135], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 63,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "Layer 3 Outlines",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [3.381, 3.38, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [-157.544, -78.383, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.729],
                    [1.729, 0],
                    [0, 1.729],
                    [-1.729, 0]
                  ],
                  "o": [
                    [0, 1.729],
                    [-1.729, 0],
                    [0, -1.729],
                    [1.729, 0]
                  ],
                  "v": [
                    [3.131, 0],
                    [0.001, 3.13],
                    [-3.131, 0],
                    [0.001, -3.13]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [3.381, 3.38], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 64,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "React",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [23.532, 23.532, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.297, "y": 0 },
              "i": { "x": 0.659, "y": -2.034 },
              "s": [90, 90, 100],
              "t": 30
            },
            {
              "o": { "x": 0.341, "y": 0.543 },
              "i": { "x": 0.802, "y": 1 },
              "s": [100, 100, 100],
              "t": 120
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [156, 156, 100],
              "t": 210
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [107, 107, 100],
              "t": 300
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [98, 98, 100],
              "t": 389
            },
            { "s": [100, 100, 100], "t": 480 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-118.27, -86.558, 0],
              "t": 30,
              "ti": [-16.488, 8.95, 0],
              "to": [24.201, -23.843, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-72, -124.3, 0],
              "t": 120,
              "ti": [-26.178, -5.096, 0],
              "to": [8.746, -4.748, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-1.1, -140, 0],
              "t": 210,
              "ti": [-19.283, -8.9, 0],
              "to": [11.88, 2.313, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [67.4, -124.3, 0],
              "t": 300,
              "ti": [-15.573, -20.583, 0],
              "to": [19.283, 8.9, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.65, "y": 1 },
              "s": [114.6, -86.6, 0],
              "t": 389,
              "ti": [-130.333, 160.638, 0],
              "to": [101.903, 134.681, 0]
            },
            { "s": [-118.3, -86.558, 0], "t": 480 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 6,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0.937],
                    [1.214, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, -0.936],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.214, 0]
                  ],
                  "v": [
                    [1.724, -1.075],
                    [-0.117, -2.554],
                    [-1.734, -2.554],
                    [-1.734, 0.405],
                    [-0.117, 0.405]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.649],
                    [1.959, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 1.639],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.959, 0]
                  ],
                  "v": [
                    [3.119, -1.075],
                    [-0.053, 1.575],
                    [-1.734, 1.575],
                    [-1.734, 3.725],
                    [-3.119, 3.725],
                    [-3.119, -3.725],
                    [-0.053, -3.725]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [31.657, 23.532], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.31, -3.725],
                    [3.31, 3.725],
                    [1.926, 3.725],
                    [1.926, 0.532],
                    [-1.926, 0.532],
                    [-1.926, 3.725],
                    [-3.31, 3.725],
                    [-3.31, -3.725],
                    [-1.926, -3.725],
                    [-1.926, -0.649],
                    [1.926, -0.649],
                    [1.926, -3.725]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [23.223, 23.532], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 6,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0.937],
                    [1.214, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, -0.936],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.214, 0]
                  ],
                  "v": [
                    [1.724, -1.075],
                    [-0.117, -2.554],
                    [-1.734, -2.554],
                    [-1.734, 0.405],
                    [-0.117, 0.405]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -1.649],
                    [1.959, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 1.639],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.959, 0]
                  ],
                  "v": [
                    [3.119, -1.075],
                    [-0.053, 1.575],
                    [-1.734, 1.575],
                    [-1.734, 3.725],
                    [-3.119, 3.725],
                    [-3.119, -3.725],
                    [-0.053, -3.725]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [15.407, 23.532], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 6,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [12.838, 0],
                    [0, -12.838],
                    [-12.838, 0],
                    [0, 12.838]
                  ],
                  "o": [
                    [-12.838, 0],
                    [0, 12.838],
                    [12.838, 0],
                    [0, -12.838]
                  ],
                  "v": [
                    [0, -23.282],
                    [-23.282, 0],
                    [0, 23.282],
                    [23.282, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-11.754, 0],
                    [0, -11.754],
                    [11.754, 0],
                    [0, 11.754]
                  ],
                  "o": [
                    [11.754, 0],
                    [0, 11.754],
                    [-11.754, 0],
                    [0, -11.754]
                  ],
                  "v": [
                    [0, -21.282],
                    [21.282, 0],
                    [0, 21.282],
                    [-21.282, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [23.532, 23.532], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [12.286, 0],
                    [0, 12.286],
                    [-12.286, 0],
                    [0, -12.287]
                  ],
                  "o": [
                    [-12.286, 0],
                    [0, -12.287],
                    [12.286, 0],
                    [0, 12.286]
                  ],
                  "v": [
                    [0, 22.282],
                    [-22.282, 0],
                    [0, -22.282],
                    [22.282, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.3333, 0.098, 0.5451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [23.532, 23.532], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 65,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "CSS",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [25.257, 25.257, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 28
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [146, 146, 100],
              "t": 120
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 210
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [90, 90, 100],
              "t": 300
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [90, 90, 100],
              "t": 390
            },
            { "s": [100, 100, 100], "t": 480 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-72.362, -124.337, 0],
              "t": 28,
              "ti": [-33.4, -1.336, 0],
              "to": [25.707, -11.387, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-1.1, -140, 0],
              "t": 120,
              "ti": [-27.731, -15.097, 0],
              "to": [23.275, 0.931, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [67.4, -124.3, 0],
              "t": 210,
              "ti": [-18.474, -18.025, 0],
              "to": [16.43, 8.945, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.65, "y": 1 },
              "s": [114.6, -86.6, 0],
              "t": 300,
              "ti": [-130.333, 160.638, 0],
              "to": [117.861, 115, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-118.3, -86.558, 0],
              "t": 390,
              "ti": [-16.488, 8.95, 0],
              "to": [24.456, -30.142, 0]
            },
            { "s": [-72, -124.3, 0], "t": 480 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.842, 0.726],
                    [0, 0],
                    [-1.248, 0],
                    [0, 0.754],
                    [0, 3.773],
                    [-2.787, 0],
                    [-0.884, -0.58],
                    [0, 0],
                    [0.885, 0],
                    [0, -0.769],
                    [0, -3.73],
                    [2.786, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.814, 0.653],
                    [1.582, 0],
                    [0, -2.191],
                    [0, -1.639],
                    [1.219, 0],
                    [0, 0],
                    [-0.913, -0.537],
                    [-1.567, 0],
                    [0, 2.162],
                    [0, 1.625],
                    [-1.581, 0]
                  ],
                  "v": [
                    [-4.056, 4.005],
                    [-3.404, 2.54],
                    [-0.051, 3.657],
                    [2.199, 2.337],
                    [-3.838, -2.191],
                    [0.254, -5.224],
                    [3.635, -4.325],
                    [3.04, -2.859],
                    [0.24, -3.657],
                    [-1.966, -2.278],
                    [4.056, 2.206],
                    [-0.051, 5.224]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [35.006, 25.147], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.841, 0.726],
                    [0, 0],
                    [-1.247, 0],
                    [0, 0.754],
                    [0, 3.773],
                    [-2.788, 0],
                    [-0.884, -0.58],
                    [0, 0],
                    [0.885, 0],
                    [0, -0.769],
                    [0, -3.73],
                    [2.786, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.812, 0.653],
                    [1.583, 0],
                    [0, -2.191],
                    [0, -1.639],
                    [1.218, 0],
                    [0, 0],
                    [-0.914, -0.537],
                    [-1.566, 0],
                    [0, 2.162],
                    [0, 1.625],
                    [-1.58, 0]
                  ],
                  "v": [
                    [-4.055, 4.005],
                    [-3.402, 2.54],
                    [-0.051, 3.657],
                    [2.199, 2.337],
                    [-3.839, -2.191],
                    [0.255, -5.224],
                    [3.634, -4.325],
                    [3.04, -2.859],
                    [0.24, -3.657],
                    [-1.967, -2.278],
                    [4.055, 2.206],
                    [-0.051, 5.224]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.874, 25.147], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 3.033],
                    [-3.121, 0],
                    [-0.944, -1.103],
                    [0, 0],
                    [1.06, 0],
                    [0, -2.09],
                    [-2.103, 0],
                    [-0.739, 0.812],
                    [0, 0],
                    [1.669, 0]
                  ],
                  "o": [
                    [0, -3.033],
                    [1.655, 0],
                    [0, 0],
                    [-0.739, -0.798],
                    [-2.103, 0],
                    [0, 2.089],
                    [1.06, 0],
                    [0, 0],
                    [-0.944, 1.103],
                    [-3.107, 0]
                  ],
                  "v": [
                    [-4.731, 0],
                    [0.711, -5.224],
                    [4.731, -3.526],
                    [3.511, -2.38],
                    [0.798, -3.57],
                    [-2.83, 0],
                    [0.798, 3.569],
                    [3.511, 2.366],
                    [4.731, 3.526],
                    [0.697, 5.224]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [16.182, 25.147], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 6,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [13.789, 0],
                    [0, -13.789],
                    [-13.789, 0],
                    [0, 13.789]
                  ],
                  "o": [
                    [-13.789, 0],
                    [0, 13.789],
                    [13.789, 0],
                    [0, -13.789]
                  ],
                  "v": [
                    [0, -25.007],
                    [-25.007, 0],
                    [0, 25.007],
                    [25.007, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-12.707, 0],
                    [0, -12.706],
                    [12.706, 0],
                    [0, 12.706]
                  ],
                  "o": [
                    [12.706, 0],
                    [0, 12.706],
                    [-12.707, 0],
                    [0, -12.706]
                  ],
                  "v": [
                    [0, -23.007],
                    [23.007, 0],
                    [0, 23.007],
                    [-23.007, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.257, 25.257], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [13.237, 0],
                    [0, 13.237],
                    [-13.237, 0],
                    [0, -13.237]
                  ],
                  "o": [
                    [-13.237, 0],
                    [0, -13.237],
                    [13.237, 0],
                    [0, 13.237]
                  ],
                  "v": [
                    [0, 24.007],
                    [-24.007, 0],
                    [0, -24.007],
                    [24.007, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.3333, 0.098, 0.5451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.257, 25.257], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 66,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "HTML",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [36.878, 36.878, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [100, 100, 100],
              "t": 28
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [67, 67, 100],
              "t": 120
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [60.3, 60.3, 100],
              "t": 210
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [60.3, 60.3, 100],
              "t": 300
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [68.3, 68.3, 100],
              "t": 390
            },
            { "s": [100, 100, 100], "t": 480 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-1.127, -140.025, 0],
              "t": 28,
              "ti": [-19.288, -8.904, 0],
              "to": [24.985, -0.039, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [67.4, -124.3, 0],
              "t": 120,
              "ti": [-16.382, -19.945, 0],
              "to": [19.288, 8.904, 0]
            },
            {
              "o": { "x": 0.387, "y": 0 },
              "i": { "x": 0.65, "y": 1 },
              "s": [114.6, -86.6, 0],
              "t": 210,
              "ti": [-130.333, 160.638, 0],
              "to": [131.159, 159.681, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-118.3, -86.558, 0],
              "t": 300,
              "ti": [-16.488, 8.95, 0],
              "to": [24.456, -30.142, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-72, -124.3, 0],
              "t": 390,
              "ti": [-26.178, -5.096, 0],
              "to": [8.746, -4.748, 0]
            },
            { "s": [-1.1, -140, 0], "t": 480 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-3.81, -5.378],
                    [-1.813, -5.378],
                    [-1.813, 3.688],
                    [3.81, 3.688],
                    [3.81, 5.378],
                    [-3.81, 5.378]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [55.448, 37.536], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.995, 5.378],
                    [3.978, -1.751],
                    [0.445, 4.149],
                    [-0.445, 4.149],
                    [-3.98, -1.66],
                    [-3.98, 5.378],
                    [-5.885, 5.378],
                    [-5.885, -5.378],
                    [-4.242, -5.378],
                    [0.031, 1.752],
                    [4.225, -5.378],
                    [5.87, -5.378],
                    [5.885, 5.378]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [42.854, 37.536], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-0.999, -3.688],
                    [-4.564, -3.688],
                    [-4.564, -5.377],
                    [4.564, -5.377],
                    [4.564, -3.688],
                    [0.998, -3.688],
                    [0.998, 5.377],
                    [-0.999, 5.377]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [30.903, 37.536], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.779, -5.378],
                    [4.779, 5.378],
                    [2.781, 5.378],
                    [2.781, 0.768],
                    [-2.782, 0.768],
                    [-2.782, 5.378],
                    [-4.779, 5.378],
                    [-4.779, -5.378],
                    [-2.782, -5.378],
                    [-2.782, -0.938],
                    [2.781, -0.938],
                    [2.781, -5.378]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [20.059, 37.536], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 6,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [20.197, 0],
                    [0, -20.197],
                    [-20.196, 0],
                    [0, 20.196]
                  ],
                  "o": [
                    [-20.196, 0],
                    [0, 20.196],
                    [20.197, 0],
                    [0, -20.197]
                  ],
                  "v": [
                    [0, -36.628],
                    [-36.627, 0.001],
                    [0, 36.628],
                    [36.627, 0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-19.124, 0],
                    [0, -19.125],
                    [19.125, 0],
                    [0, 19.124]
                  ],
                  "o": [
                    [19.125, 0],
                    [0, 19.124],
                    [-19.124, 0],
                    [0, -19.125]
                  ],
                  "v": [
                    [0, -34.628],
                    [34.627, 0.001],
                    [0, 34.628],
                    [-34.627, 0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [36.877, 36.878], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 6",
          "ix": 6,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [19.646, 0],
                    [0, 19.645],
                    [-19.645, 0],
                    [0, -19.646]
                  ],
                  "o": [
                    [-19.645, 0],
                    [0, -19.646],
                    [19.646, 0],
                    [0, 19.645]
                  ],
                  "v": [
                    [0, 35.627],
                    [-35.627, 0.001],
                    [0, -35.627],
                    [35.627, 0.001]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [36.877, 36.878], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 67,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "c++",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [25.257, 25.257, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [100, 100, 100],
              "t": 28
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [90, 90, 100],
              "t": 120
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.661, "y": 1 },
              "s": [90, 90, 100],
              "t": 210
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.815, "y": 1 },
              "s": [100, 100, 100],
              "t": 300
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [156, 156, 100],
              "t": 390
            },
            { "s": [100, 100, 100], "t": 480 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [67.392, -124.337, 0],
              "t": 28,
              "ti": [-16.562, -19.796, 0],
              "to": [30.474, 15.066, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [114.6, -86.6, 0],
              "t": 120,
              "ti": [-130.333, 160.638, 0],
              "to": [112.01, 133.883, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-118.3, -86.558, 0],
              "t": 210,
              "ti": [-16.488, 8.95, 0],
              "to": [24.456, -30.142, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-72, -124.3, 0],
              "t": 300,
              "ti": [-26.178, -5.096, 0],
              "to": [8.746, -4.748, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-1.1, -140, 0],
              "t": 390,
              "ti": [-11.417, -2.617, 0],
              "to": [11.88, 2.313, 0]
            },
            { "s": [67.4, -124.3, 0], "t": 480 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.075, 0.667],
                    [0.713, 0.667],
                    [0.713, 3.001],
                    [-0.714, 3.001],
                    [-0.714, 0.667],
                    [-3.075, 0.667],
                    [-3.075, -0.667],
                    [-0.714, -0.667],
                    [-0.714, -3.001],
                    [0.713, -3.001],
                    [0.713, -0.667],
                    [3.075, -0.667]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [35.429, 25.147], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.075, 0.667],
                    [0.713, 0.667],
                    [0.713, 3.001],
                    [-0.714, 3.001],
                    [-0.714, 0.667],
                    [-3.075, 0.667],
                    [-3.075, -0.667],
                    [-0.714, -0.667],
                    [-0.714, -3.001],
                    [0.713, -3.001],
                    [0.713, -0.667],
                    [3.075, -0.667]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [27.594, 25.147], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, 3.288],
                    [-3.382, 0],
                    [-1.024, -1.195],
                    [0, 0],
                    [1.148, 0],
                    [0, -2.266],
                    [-2.281, 0],
                    [-0.802, 0.881],
                    [0, 0],
                    [1.808, 0]
                  ],
                  "o": [
                    [0, -3.288],
                    [1.793, 0],
                    [0, 0],
                    [-0.802, -0.865],
                    [-2.281, 0],
                    [0, 2.265],
                    [1.148, 0],
                    [0, 0],
                    [-1.024, 1.196],
                    [-3.367, 0]
                  ],
                  "v": [
                    [-5.128, -0.001],
                    [0.77, -5.663],
                    [5.128, -3.824],
                    [3.807, -2.58],
                    [0.865, -3.87],
                    [-3.068, -0.001],
                    [0.865, 3.87],
                    [3.807, 2.564],
                    [5.128, 3.823],
                    [0.755, 5.663]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [17.14, 25.147], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 6,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [13.789, 0],
                    [0, -13.789],
                    [-13.789, 0],
                    [0, 13.789]
                  ],
                  "o": [
                    [-13.789, 0],
                    [0, 13.789],
                    [13.789, 0],
                    [0, -13.789]
                  ],
                  "v": [
                    [0, -25.007],
                    [-25.007, 0],
                    [0, 25.007],
                    [25.007, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-12.706, 0],
                    [0, -12.706],
                    [12.706, 0],
                    [0, 12.706]
                  ],
                  "o": [
                    [12.706, 0],
                    [0, 12.706],
                    [-12.706, 0],
                    [0, -12.706]
                  ],
                  "v": [
                    [0, -23.007],
                    [23.007, 0],
                    [0, 23.007],
                    [-23.007, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.257, 25.257], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 5",
          "ix": 5,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [13.237, 0],
                    [0, 13.237],
                    [-13.237, 0],
                    [0, -13.237]
                  ],
                  "o": [
                    [-13.237, 0],
                    [0, -13.237],
                    [13.237, 0],
                    [0, 13.237]
                  ],
                  "v": [
                    [0, 24.007],
                    [-24.007, 0],
                    [0, -24.007],
                    [24.007, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.3333, 0.098, 0.5451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [25.257, 25.257], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 68,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "JS",
      "sr": 1,
      "st": 0,
      "op": 480,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [23.532, 23.532, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.335, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [90, 90, 100],
              "t": 28
            },
            {
              "o": { "x": 0.33, "y": 0 },
              "i": { "x": 0.661, "y": 1 },
              "s": [90, 90, 100],
              "t": 120
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.815, "y": 1 },
              "s": [100, 100, 100],
              "t": 210
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [156, 156, 100],
              "t": 300
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0.833, "y": 1 },
              "s": [107, 107, 100],
              "t": 390
            },
            { "s": [100, 100, 100], "t": 480 }
          ],
          "ix": 6
        },
        "sk": { "a": 0, "k": 0 },
        "p": {
          "a": 1,
          "k": [
            {
              "o": { "x": 0.669, "y": 0 },
              "i": { "x": 0.667, "y": 1 },
              "s": [114.594, -86.558, 0],
              "t": 28,
              "ti": [-130.333, 160.638, 0],
              "to": [134.057, 147.872, 0]
            },
            {
              "o": { "x": 0.333, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-118.3, -86.558, 0],
              "t": 120,
              "ti": [-16.488, 8.95, 0],
              "to": [24.456, -30.142, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-72, -124.3, 0],
              "t": 210,
              "ti": [-26.653, -0.959, 0],
              "to": [8.746, -4.748, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [-1.1, -140, 0],
              "t": 300,
              "ti": [-19.283, -8.9, 0],
              "to": [27.305, 0.983, 0]
            },
            {
              "o": { "x": 0.167, "y": 0 },
              "i": { "x": 0, "y": 1 },
              "s": [67.4, -124.3, 0],
              "t": 390,
              "ti": [-15.573, -20.583, 0],
              "to": [19.283, 8.9, 0]
            },
            { "s": [114.6, -86.6, 0], "t": 480 }
          ],
          "ix": 2
        },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.835, 0.592],
                    [0, 0],
                    [-1.166, 0],
                    [0, 0.644],
                    [0, 3.708],
                    [-2.507, 0],
                    [-0.837, -0.505],
                    [0, 0],
                    [0.887, 0],
                    [0, -0.61],
                    [0, -3.637],
                    [2.612, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.819, 0.539],
                    [1.375, 0],
                    [0, -1.775],
                    [0, -1.758],
                    [1.235, 0],
                    [0, 0],
                    [-0.887, -0.522],
                    [-1.323, 0],
                    [0, 1.88],
                    [0, 1.741],
                    [-1.549, 0]
                  ],
                  "v": [
                    [-4.073, 3.716],
                    [-3.238, 2.064],
                    [0, 2.987],
                    [1.949, 1.958],
                    [-3.795, -1.853],
                    [0.296, -4.76],
                    [3.778, -3.96],
                    [2.941, -2.305],
                    [0.279, -3.003],
                    [-1.671, -1.957],
                    [4.073, 1.889],
                    [-0.14, 4.76]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [28.414, 24.462], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 2",
          "ix": 2,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0.836, 1.131],
                    [0, 0],
                    [-0.957, 0],
                    [0, 1.497],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.68, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0.662, 0.905],
                    [1.254, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.872],
                    [-1.531, 0]
                  ],
                  "v": [
                    [-3.933, 4.421],
                    [-2.645, 2.872],
                    [-0.226, 4.264],
                    [1.671, 2.02],
                    [1.671, -4.281],
                    [-2.662, -4.281],
                    [-2.662, -6.18],
                    [3.933, -6.18],
                    [3.933, 1.897],
                    [-0.174, 6.18]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [18.504, 23.096], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 3",
          "ix": 3,
          "cix": 2,
          "np": 6,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [12.838, 0],
                    [0, -12.838],
                    [-12.838, 0],
                    [0, 12.838]
                  ],
                  "o": [
                    [-12.838, 0],
                    [0, 12.838],
                    [12.838, 0],
                    [0, -12.838]
                  ],
                  "v": [
                    [0.001, -23.282],
                    [-23.281, 0],
                    [0.001, 23.282],
                    [23.281, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 2",
              "ix": 2,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [-11.754, 0],
                    [0, -11.754],
                    [11.754, 0],
                    [0, 11.754]
                  ],
                  "o": [
                    [11.754, 0],
                    [0, 11.754],
                    [-11.754, 0],
                    [0, -11.754]
                  ],
                  "v": [
                    [0.001, -21.282],
                    [21.281, 0],
                    [0.001, 21.282],
                    [-21.281, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "mm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Merge",
              "nm": "Merge Paths 1",
              "mm": 1
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [1, 1, 1], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [23.531, 23.532], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        },
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 4",
          "ix": 4,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [12.286, 0],
                    [0, 12.286],
                    [-12.286, 0],
                    [0, -12.287]
                  ],
                  "o": [
                    [-12.286, 0],
                    [0, -12.287],
                    [12.286, 0],
                    [0, 12.286]
                  ],
                  "v": [
                    [0.001, 22.282],
                    [-22.281, 0],
                    [0.001, -22.282],
                    [22.281, 0]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [23.531, 23.532], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 69,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "path",
      "sr": 1,
      "st": 0,
      "op": 481,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [157.197, 157.198, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 9.953, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -85.438],
                    [85.436, 0],
                    [0, 85.437],
                    [-85.438, 0]
                  ],
                  "o": [
                    [0, 85.437],
                    [-85.438, 0],
                    [0, -85.438],
                    [85.436, 0]
                  ],
                  "v": [
                    [154.698, 0],
                    [0, 154.698],
                    [-154.697, 0],
                    [0, -154.698]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 1, "ix": 5 },
              "c": { "a": 0, "k": [0.1569, 0.1569, 0.1725], "ix": 3 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [157.198, 157.198], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 70,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "bg circle",
      "sr": 1,
      "st": 0,
      "op": 481,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": { "a": 0, "k": [144.191, 144.191, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 },
        "sk": { "a": 0, "k": 0 },
        "p": { "a": 0, "k": [0, 9.952, 0], "ix": 2 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "sa": { "a": 0, "k": 0 },
        "o": { "a": 0, "k": 100, "ix": 11 }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 4,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [0, -79.497],
                    [79.496, 0],
                    [0, 79.496],
                    [-79.497, 0]
                  ],
                  "o": [
                    [0, 79.496],
                    [-79.497, 0],
                    [0, -79.497],
                    [79.496, 0]
                  ],
                  "v": [
                    [143.942, 0.001],
                    [0.001, 143.941],
                    [-143.941, 0.001],
                    [0.001, -143.941]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "fl",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Fill",
              "nm": "Fill 1",
              "c": { "a": 0, "k": [0.3333, 0.098, 0.5451], "ix": 4 },
              "r": 1,
              "o": { "a": 0, "k": 100, "ix": 5 }
            },
            {
              "ty": "tr",
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "p": { "a": 0, "k": [144.191, 144.191], "ix": 2 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "o": { "a": 0, "k": 100, "ix": 7 }
            }
          ]
        }
      ],
      "ind": 71,
      "parent": 1
    }
  ],
  "v": "5.7.3",
  "fr": 30,
  "op": 480,
  "ip": 0,
  "assets": []
}
