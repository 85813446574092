{
  "v": "5.7.1",
  "fr": 30,
  "ip": 0,
  "op": 30,
  "w": 1920,
  "h": 1080,
  "nm": "Lottie Tutorial New",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "evelope",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 0,
              "s": [959.906, 561.464, 0],
              "to": [0, -7.333, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.167, "y": 0 },
              "t": 14,
              "s": [959.906, 517.464, 0],
              "to": [0, 0, 0],
              "ti": [0, -7.333, 0]
            },
            { "t": 28.0000011404634, "s": [959.906, 561.464, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [210.768, 158.138, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [7.26, 0],
                    [0, 0],
                    [0, 7.261],
                    [0, 0],
                    [0, 0],
                    [-3.071, 0],
                    [-2.48, 2.146],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-7.26, 0],
                    [0, 0],
                    [0, 0],
                    [2.48, 2.145],
                    [3.071, 0],
                    [0, 0],
                    [0, 0],
                    [-0.001, 7.26]
                  ],
                  "v": [
                    [171.045, 131.573],
                    [-171.044, 131.573],
                    [-184.202, 118.415],
                    [-184.202, -102.753],
                    [-8.622, 49.417],
                    [0, 52.629],
                    [8.622, 49.417],
                    [184.203, -102.753],
                    [184.203, 118.416]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 1,
              "ty": "sh",
              "ix": 2,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [-1.555, -0.647],
                    [0, 0],
                    [0, 0],
                    [-1.788, 0]
                  ],
                  "o": [
                    [1.788, 0],
                    [0, 0],
                    [0, 0],
                    [1.554, -0.646],
                    [0, 0]
                  ],
                  "v": [
                    [171.045, -131.573],
                    [176.088, -130.555],
                    [0, 22.061],
                    [-176.088, -130.555],
                    [-171.045, -131.573]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 2",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 2,
              "ty": "sh",
              "ix": 3,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [21.767, 0],
                    [0, 0],
                    [0, -21.766],
                    [0, 0],
                    [-21.767, 0],
                    [0, 0],
                    [0, 21.766],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-21.767, 0],
                    [0, 0],
                    [0, 21.766],
                    [0, 0],
                    [21.767, 0],
                    [0, 0],
                    [0, -21.766]
                  ],
                  "v": [
                    [171.045, -157.888],
                    [-171.044, -157.888],
                    [-210.517, -118.416],
                    [-210.517, 118.416],
                    [-171.044, 157.888],
                    [171.045, 157.888],
                    [210.517, 118.416],
                    [210.517, -118.416]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 3",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "mm",
              "mm": 1,
              "nm": "Fusionner les tracés 1",
              "mn": "ADBE Vector Filter - Merge",
              "hd": false
            },
            {
              "ty": "fl",
              "c": { "a": 0, "k": [0.4, 0, 1, 1], "ix": 4 },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fond 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [210.767, 158.138], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 5,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "evelope-shadow",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.333, "y": 0 },
              "t": 0,
              "s": [1043.578, 612.154, 0],
              "to": [0, -7.333, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.667, "y": 1 },
              "o": { "x": 0.167, "y": 0 },
              "t": 14,
              "s": [1043.578, 568.154, 0],
              "to": [0, 0, 0],
              "ti": [0, -7.333, 0]
            },
            { "t": 28.0000011404634, "s": [1043.578, 612.154, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [115.3, 100.942, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [-6.181, 185.983],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [115.05, -100.692],
                    [115.05, 77.015],
                    [91.371, 100.692],
                    [-115.05, 100.692]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.8862745098039215, 0.8235294117647058, 1, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fond 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [115.3, 100.942], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "wave",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 0,
              "s": [775.703, 248.429, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 14,
              "s": [775.703, 254.42900000000003, 0],
              "to": [0, 0, 0],
              "ti": [0, 0, 0]
            },
            { "t": 28.0000011404634, "s": [775.703, 248.429, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [76.35, 61.296, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [-18.541, -18.541],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [18.541, 18.541],
                    [0, 0]
                  ],
                  "v": [
                    [-33.595, 0],
                    [1.267, 0],
                    [33.595, 0]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.8862745098039215, 0.8235294117647058, 1, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 17.102, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Contour 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [76.35, 61.296], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "circle1",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 0,
              "s": [666.721, 728.644, 0],
              "to": [0, 2.167, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 14,
              "s": [666.721, 741.644, 0],
              "to": [0, 0, 0],
              "ti": [0, 2.167, 0]
            },
            { "t": 28.0000011404634, "s": [666.721, 728.644, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [57.806, 57.806, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, -8.313],
                    [8.313, 0],
                    [0, 8.313],
                    [-8.312, 0]
                  ],
                  "o": [
                    [0, 8.313],
                    [-8.312, 0],
                    [0, -8.313],
                    [8.313, 0]
                  ],
                  "v": [
                    [15.051, 0],
                    [0, 15.051],
                    [-15.051, 0],
                    [0, -15.051]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.8862745098039215, 0.8235294117647058, 1, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 17.102, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Contour 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [57.806, 57.806], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "plus",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 0,
              "s": [1257.728, 623.464, 0],
              "to": [0, 1.589, 0],
              "ti": [0, -0.535, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 14,
              "s": [1257.728, 633, 0],
              "to": [0, 0.535, 0],
              "ti": [0, 1.054, 0]
            },
            { "t": 28.0000011404634, "s": [1257.728, 626.677, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [33.16, 8.725, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [32.91, 8.475],
                    [-32.91, 8.475],
                    [-32.91, -8.475],
                    [32.91, -8.475]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.8862745098039215, 0.8235294117647058, 1, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fond 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [33.16, 8.725], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "plus2",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 0,
              "s": [1257.728, 623.264, 0],
              "to": [0, 1.623, 0],
              "ti": [0, -0.569, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 14,
              "s": [1257.728, 633, 0],
              "to": [0, 0.569, 0],
              "ti": [0, 1.054, 0]
            },
            { "t": 28.0000011404634, "s": [1257.728, 626.677, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [8.724, 33.16, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [-8.474, -32.91],
                    [8.474, -32.91],
                    [8.474, 32.91],
                    [-8.474, 32.91]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.8862745098039215, 0.8235294117647058, 1, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fond 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [8.724, 33.16], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "circle2",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": {
          "a": 1,
          "k": [
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 0,
              "s": [1249.252, 355.177, 0],
              "to": [0, -1, 0],
              "ti": [0, 0, 0]
            },
            {
              "i": { "x": 0.833, "y": 0.833 },
              "o": { "x": 0.167, "y": 0.167 },
              "t": 14,
              "s": [1249.252, 349.177, 0],
              "to": [0, 0, 0],
              "ti": [0, -1, 0]
            },
            { "t": 28.0000011404634, "s": [1249.252, 355.177, 0] }
          ],
          "ix": 2
        },
        "a": { "a": 0, "k": [67.097, 67.097, 0], "ix": 1 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, -13.444],
                    [13.444, 0],
                    [0, 13.444],
                    [-13.444, 0]
                  ],
                  "o": [
                    [0, 13.444],
                    [-13.444, 0],
                    [0, -13.444],
                    [13.444, 0]
                  ],
                  "v": [
                    [24.343, 0],
                    [0, 24.342],
                    [-24.343, 0],
                    [0, -24.342]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.8862745098039215, 0.8235294117647058, 1, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 17.102, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Contour 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [67.097, 67.097], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "shadow",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [959.905, 865.667, 0], "ix": 2 },
        "a": { "a": 0, "k": [153.686, 22.08, 0], "ix": 1 },
        "s": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833] },
              "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167] },
              "t": 0,
              "s": [100, 100, 100]
            },
            {
              "i": { "x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833] },
              "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167] },
              "t": 14,
              "s": [81, 81, 100]
            },
            { "t": 28.0000011404634, "s": [100, 100, 100] }
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, -12.057],
                    [84.742, 0],
                    [0, 12.057],
                    [-84.74, 0]
                  ],
                  "o": [
                    [0, 12.057],
                    [-84.74, 0],
                    [0, -12.057],
                    [84.742, 0]
                  ],
                  "v": [
                    [153.436, -0.001],
                    [-0.001, 21.831],
                    [-153.436, -0.001],
                    [-0.001, -21.831]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Tracé 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.8862745098039215, 0.8235294117647058, 1, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fond 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [153.686, 22.081], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transformer "
            }
          ],
          "nm": "Groupe 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [
    { "tm": 0, "cm": "1", "dr": 0 },
    { "tm": 395.00001608868, "cm": "2", "dr": 0 }
  ]
}
