const css = `.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  gap: 1rem 1rem;
}

.experience-heading {
  font-size: 56px;
  font-weight: 400;
}

.experience-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.experience-container > * {
  flex: 1;
}

/* Media Query */
@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RpeGl0LmphaW4vZGV2cG9ydGZvbGlvL2FwcHMvcG9ydGZvbGlvLXVpL3NyYy9jb250YWluZXJzL3dvcmtFeHBlcmllbmNlIiwic291cmNlcyI6WyJXb3JrRXhwZXJpZW5jZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTs7O0FBR0Y7QUFFQTtFQUNFO0lBQ0U7OztBQUlKO0VBQ0U7SUFDRTtJQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmV4cGVyaWVuY2UtY2FyZHMtZGl2IHtcbiAgZGlzcGxheTogZ3JpZDtcbiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiByZXBlYXQoYXV0by1maXQsIG1pbm1heCgyOTBweCwgMWZyKSk7XG4gIGdhcDogMXJlbSAxcmVtO1xufVxuXG4uZXhwZXJpZW5jZS1oZWFkaW5nIHtcbiAgZm9udC1zaXplOiA1NnB4O1xuICBmb250LXdlaWdodDogNDAwO1xufVxuXG4uZXhwZXJpZW5jZS1jb250YWluZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICB3aWR0aDogOTAlO1xuICBwYWRkaW5nOiAyMHB4IDEwcHg7XG4gIG1hcmdpbjogMHB4IGF1dG87XG4gIG1hcmdpbi10b3A6IDJyZW07XG59XG5cbi5leHBlcmllbmNlLWNvbnRhaW5lciA+ICoge1xuICBmbGV4OiAxO1xufVxuXG4vKiBNZWRpYSBRdWVyeSAqL1xuXG5AbWVkaWEgKG1heC13aWR0aDogMTM4MHB4KSB7XG4gIC5leHBlcmllbmNlLWhlYWRpbmcge1xuICAgIGZvbnQtc2l6ZTogNDBweDtcbiAgfVxufVxuXG5AbWVkaWEgKG1heC13aWR0aDogNzY4cHgpIHtcbiAgLmV4cGVyaWVuY2UtaGVhZGluZyB7XG4gICAgZm9udC1zaXplOiAzMHB4O1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgfVxufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
