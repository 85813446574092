const css = `@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"), url("./fonts/Agustina.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font: 19px/23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RpeGl0LmphaW4vZGV2cG9ydGZvbGlvL2FwcHMvcG9ydGZvbGlvLXVpL3NyYyIsInNvdXJjZXMiOlsiaW5kZXguc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBLEtBQ0U7RUFFRjs7QUFFRjtFQUNFO0VBQ0EsS0FDRTtFQUVGOztBQUVGO0VBQ0U7OztBQUdGO0FBQUE7RUFFRTs7O0FBRUY7RUFDRTtFQUNBLE1BQ0U7RUFHRjtFQUNBOzs7QUFFRjtBQUNBO0VBQ0U7QUFBQTtJQUVFO0lBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyJAZm9udC1mYWNlIHtcbiAgZm9udC1mYW1pbHk6IFwiQWd1c3RpbmEgUmVndWxhclwiO1xuICBmb250LXN0eWxlOiBub3JtYWw7XG4gIGZvbnQtd2VpZ2h0OiBub3JtYWw7XG4gIHNyYzpcbiAgICBsb2NhbChcIkFndXN0aW5hIFJlZ3VsYXJcIiksXG4gICAgdXJsKFwiLi9mb250cy9BZ3VzdGluYS53b2ZmXCIpIGZvcm1hdChcIndvZmZcIik7XG4gIGZvbnQtZGlzcGxheTogc3dhcDtcbn1cbkBmb250LWZhY2Uge1xuICBmb250LWZhbWlseTogXCJNb250c2VycmF0XCI7XG4gIHNyYzpcbiAgICBsb2NhbChcIk1vbnRzZXJyYXRcIiksXG4gICAgdXJsKFwiLi9mb250cy9Nb250c2VycmF0LVJlZ3VsYXIudHRmXCIpIGZvcm1hdChcIndvZmZcIik7XG4gIGZvbnQtZGlzcGxheTogc3dhcDtcbn1cbmNvZGUge1xuICBmb250LWZhbWlseTogc291cmNlLWNvZGUtcHJvLCBNZW5sbywgTW9uYWNvLCBDb25zb2xhcywgXCJDb3VyaWVyIE5ld1wiLFxuICAgIG1vbm9zcGFjZTtcbn1cbmh0bWwsXG5ib2R5IHtcbiAgc2Nyb2xsLWJlaGF2aW9yOiBzbW9vdGg7XG59XG5ib2R5IHtcbiAgbWFyZ2luOiAwO1xuICBmb250OlxuICAgIDE5cHggLyAyM3B4IE1vbnRzZXJyYXQsXG4gICAgXCJNb250c2VycmF0XCIsXG4gICAgc2Fucy1zZXJpZjtcbiAgLXdlYmtpdC1mb250LXNtb290aGluZzogYW50aWFsaWFzZWQ7XG4gIC1tb3otb3N4LWZvbnQtc21vb3RoaW5nOiBncmF5c2NhbGU7XG59XG4vKiBNZWRpYSBRdWVyeSAqL1xuQG1lZGlhIChtYXgtd2lkdGg6IDEzODBweCkge1xuICBodG1sLFxuICBib2R5IHtcbiAgICBmb250LXNpemU6IHJlbTtcbiAgICBsaW5lLWhlaWdodDogbm9ybWFsO1xuICB9XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
